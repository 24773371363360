import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../../components/templates/layout"
import CustomerGroups from "../../../components/views/retail/customers/groups"

const Orders = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <CustomerGroups />
      </Layout>
    </DndProvider>
  )
}

export default Orders
